import * as types from "../../constants";

export default function reducer(state = {}, actions) {
  switch (actions.type) {
    case types.DEVICE_STATE_SUCCESS:
      return {
        ...state,
        devices: actions.devices.data,
      };

    case types.DEVICE_STATE_FAILURE:
      return {
        ...state,
        devices: null,
      };

    default:
      return state;
  }
}
