import { removeUndefinedProps } from "@material-ui/data-grid";
import * as types from "../../constants";

const initial = {
  user: null,
}

if (localStorage.getItem("PENSORUSER")) {
  try {
    const user = JSON.parse(localStorage.getItem("PENSORUSER"));
    if (user) {
      initial.user = user;
    }
  } catch {

  }
}

if (initial.user && (!initial.user.id || !initial.user.username || !initial.user.status)) {
  initial.user = null;
}

export default function reducer(state = initial, actions) {
  switch (actions.type) {
    case types.AUTH_SIGN_IN_SUCCESS:
      const date = new Date();
      const tokenExpired = date.setMonth(date.getMonth() + 1);
      const user = {
        id: actions.id,
        username: actions.id.username,
        status: 'Authenticated',
        tokenExpired
      }
      localStorage.setItem("PENSORUSER", JSON.stringify(user));
      return {
        ...state,
        user,
      };
    case types.AUTH_SIGN_IN_FAILURE:
      return {
        ...state,
        user: undefined,
      }
    case types.AUTH_SIGN_OUT:
      localStorage.setItem("PENSORUSER", null);
      return {
        ...state,
        user: undefined,
      };
    case types.AUTH_SIGN_UP_SUCCESS:
      return {
        ...state,
        signupUser: actions.user,
        userConfirmed: actions.userConfirmed,
        userSub: actions.userSub
      };
    case types.AUTH_SIGN_UP_FAILURE:
      return {
        ...state,
        user: undefined,
      }
    case types.AUTH_SIGN_UP_CONFIRM_REQUEST:
      return {
        ...state,
      }
    case types.AUTH_SIGN_UP_CONFIRM_SUCCESS:
      return {
        ...state,
        signupUser: null,
        userConfirmed: false,
        userSub: null
      }
    case types.AUTH_SIGN_UP_CONFIRM_FAILURE:
      return {
        ...state,
        user: undefined,
      }
    case types.AUTH_CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
      }
    case types.AUTH_CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
      }
    case types.AUTH_NEW_ACCESS_TOKEN:
      state.user.id.signInUserSession.accessToken.jwtToken = actions.access_token;
      localStorage.setItem("PENSORUSER", JSON.stringify(state.user));
      return {
        ...state,
      }
    case types.AUTH_API_USER_INFO:
      return {
        ...state,
        userInfo: actions.userInfo
      }
    default:
      return state;
  }
}
