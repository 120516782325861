// Theme
export const THEME_SET = "THEME_SET";
export const THEMES = {
  DEFAULT: "DEFAULT",
  DARK: "DARK",
  LIGHT: "LIGHT",
  BLUE: "BLUE",
  GREEN: "GREEN",
  INDIGO: "INDIGO",
};

// Auth
export const AUTH_SIGN_IN_REQUEST = "AUTH_SIGN_IN_REQUEST";
export const AUTH_SIGN_IN_SUCCESS = "AUTH_SIGN_IN_SUCCESS";
export const AUTH_SIGN_IN_FAILURE = "AUTH_SIGN_IN_FAILURE";
export const AUTH_SIGN_OUT = "AUTH_SIGN_OUT";
export const AUTH_SIGN_UP_REQUEST = "AUTH_SIGN_UP_REQUEST";
export const AUTH_SIGN_UP_SUCCESS = "AUTH_SIGN_UP_SUCCESS";
export const AUTH_SIGN_UP_FAILURE = "AUTH_SIGN_UP_FAILURE";
export const AUTH_SIGN_UP_CONFIRM_REQUEST = "AUTH_SIGN_UP_CONFIRM_REQUEST";
export const AUTH_SIGN_UP_CONFIRM_SUCCESS = "AUTH_SIGN_UP_CONFIRM_SUCCESS";
export const AUTH_SIGN_UP_CONFIRM_FAILURE = "AUTH_SIGN_UP_CONFIRM_FAILURE";
export const AUTH_RESET_PASSWORD_REQUEST = "AUTH_RESET_PASSWORD_REQUEST";
export const AUTH_RESET_PASSWORD_SUCCESS = "AUTH_RESET_PASSWORD_SUCCESS";
export const AUTH_RESET_PASSWORD_FAILURE = "AUTH_RESET_PASSWORD_FAILURE";
export const AUTH_CHANGE_PASSWORD_REQUEST = "AUTH_CHANGE_PASSWORD_REQUEST";
export const AUTH_CHANGE_PASSWORD_SUCCESS = "AUTH_CHANGE_PASSWORD_SUCCESS";
export const AUTH_CHANGE_PASSWORD_FAILURE = "AUTH_CHANGE_PASSWORD_SUCCESS";
export const AUTH_RENEW_PASSWORD_REQUEST = "AUTH_RENEW_PASSWORD_REQUEST";
export const AUTH_RENEW_PASSWORD_SUCCESS = "AUTH_RENEW_PASSWORD_SUCCESS";
export const AUTH_RENEW_PASSWORD_FAILURE = "AUTH_RENEW_PASSWORD_FAILURE";
export const AUTH_NEW_ACCESS_TOKEN = "AUTH_NEW_ACCESS_TOKEN";
export const AUTH_API_USER_INFO = "AUTH_API_USER_INFO";

// DEVICES
export const DEVICE_STATE_REQUEST = "DEVICE_STATE_REQUEST";
export const DEVICE_STATE_SUCCESS = "DEVICE_STATE_SUCCESS";
export const DEVICE_STATE_FAILURE = "DEVICE_STATE_FAILURE";
// SCRIPTS
export const SCRIPT_STATE_REQUEST = "SCRIPT_STATE_REQUEST";
export const SCRIPT_STATE_SUCCESS = "SCRIPT_STATE_SUCCESS";
export const SCRIPT_STATE_FAILURE = "SCRIPT_STATE_FAILURE";
export const SCRIPT_DETAILS_REQUEST = "SCRIPT_DETAILS_REQUEST";
export const SCRIPT_DETAILS_SUCCESS = "SCRIPT_DETAILS_SUCCESS";
export const SCRIPT_DETAILS_FAILURE = "SCRIPT_DETAILS_FAILURE";
// DATA
export const DATA_STATE_PROJECTS = "DATA_STATE_PROJECTS";