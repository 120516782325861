import React, { useEffect, useRef, useState } from "react";
import styled, { createGlobalStyle } from "styled-components/macro";
import Sidebar from "../components/Sidebar";
import Header from "../components/AppBar";
import Footer from "../components/Footer";
import Settings from "../components/Settings";

import { spacing } from "@material-ui/system";
import {
  Hidden,
  CssBaseline,
  Paper as MuiPaper,
  withWidth,
  withStyles,
  Box,
} from "@material-ui/core";

import { isWidthUp } from "@material-ui/core/withWidth";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { signOut, checkAccessToken, apiUserInfo } from "../redux/actions/authActions";


const drawerWidth = 258;

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: ${(props) => props.theme.palette.background.default};
  }

  .MuiCardHeader-action .MuiIconButton-root {
    padding: 4px;
    width: 28px;
    height: 28px;
  }
`;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
  overflow-x:hidden;
`;

const Drawer = styled.div`
  ${(props) => props.theme.breakpoints.up("md")} {
    width: ${drawerWidth}px;
    flex-shrink: 0;
  }
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  flex: 1;
  flex-grow: 1;
  padding:30px 10px;
  padding-bottom:0px;
  background: ${(props) => props.theme.palette.background.default};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

const styles = theme => ({
  root: {
    display: "flex"
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36
  },
  menuButtonIconClosed: {
    transition: theme.transitions.create(["transform"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    transform: "rotate(0deg)"
  },
  menuButtonIconOpen: {
    transition: theme.transitions.create(["transform"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    transform: "rotate(180deg)"
  },
  hide: {
    display: "none"
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    position: "relative",
    overflowX: "hidden"
  },
  drawerPaper: {
    position: "absolute",
    overflowX: "hidden"
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: "hidden",
    width: theme.spacing.unit * 14 + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing.unit * 14 + 1
    }
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing.unit,
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3
  },
  grow: {
    flexGrow: 1
  }
});

let staticOpen = true;

const Dashboard = ({ children, routes, width, classes, theme, name }) => {
  const [open, setOpen] = useState(staticOpen);
  const dispatch = useDispatch();
  const history = useHistory();
  const { user, userInfo } = useSelector((state) => state.authReducer, shallowEqual);


  const handleDrawerToggle = () => {
    staticOpen = !open;
    setOpen(!open);
    // drawerRef.current.handleDrawerToggle();
  };

  useEffect(async () => {
    console.log("dashboard use effect", userInfo)
    if (!user) {
      history.push("/auth/sign-in");
    } else {
      if (user.tokenExpired < Date.now()) {
        await dispatch(
          signOut()
        );
        return null;
      }
      /*await dispatch(
        checkAccessToken(user)
      );
      if (!userInfo) {
        await dispatch(
          apiUserInfo(user)
        );
      }*/
    }
  })

  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      {/* <Drawer>
        <Hidden mdUp implementation="js">
          <Sidebar
            routes={routes}
            PaperProps={{ style: { width: drawerWidth } }}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
          />
        </Hidden>
        <Hidden smDown implementation="css">
          <Sidebar
            routes={routes}
            PaperProps={{ style: { width: drawerWidth } }}
          />
        </Hidden>
      </Drawer> */}
      <AppContent>
        <Box display={"flex"} height={"100%"}>
          <Sidebar
            open={open}
            classes={classes}
            routes={routes}
          />
          <Box display={"flex"} flexDirection={"column"} flex={1}>
            <Header open={open} onDrawerToggle={handleDrawerToggle} name={name} />
            <MainContent>
              {children}
            </MainContent>
            <Footer />
          </Box>
        </Box>
      </AppContent>
      {/* <Settings /> */}
    </Root>
  );
};

// export default withWidth()(Dashboard);
export default withStyles(styles, { withTheme: true })(Dashboard);
