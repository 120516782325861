import * as types from "../../constants";
import {
  signIn as authSignIn,
  signUp as authSignUp,
  resetPassword as authResetPassword,
  changePassword as authChangePassword,
} from "../../services/authService";
import { Auth } from "aws-amplify";
import jwt_decode from "jwt-decode";
import axios from "axios";
import querystring from 'querystring';

export function signIn(credentials) {
  return async (dispatch) => {
    dispatch({ type: types.AUTH_SIGN_IN_REQUEST });
    try {
      const res = await Auth.signIn(credentials.email, credentials.password)
      dispatch({
        type: types.AUTH_SIGN_IN_SUCCESS,
        id: res,
      });
    } catch (error) {
      dispatch({
        type: types.AUTH_SIGN_IN_FAILURE,
      });
      throw error;
    }
  }
}

export function signUp(credentials) {
  return async (dispatch) => {
    dispatch({ type: types.AUTH_SIGN_UP_REQUEST });
    try {
      const { user, userConfirmed, userSub } = await Auth.signUp({
        username: credentials.email,
        password: credentials.password,
        attributes: {
          'custom:Name': credentials.name,
          'custom:Company': credentials.company
        },
      });
      dispatch({
        type: types.AUTH_SIGN_UP_SUCCESS,
        user: user,
        userConfirmed: userConfirmed,
        userSub: userSub
      })
    } catch (error) {
      dispatch({
        type: types.AUTH_SIGN_UP_FAILURE,
      });
      throw error;
    }
  };
}


export function confirmSignUp(user, code) {
  return async (dispatch) => {
    dispatch({ type: types.AUTH_SIGN_UP_CONFIRM_REQUEST });

    try {
      await Auth.confirmSignUp(user.username, code);
      dispatch({
        type: types.AUTH_SIGN_UP_CONFIRM_SUCCESS,
      })
    } catch (error) {
      dispatch({
        type: types.AUTH_SIGN_UP_CONFIRM_FAILURE,
      });
      throw error;
    }
  }
}
export function signOut() {
  return async (dispatch) => {
    dispatch({
      type: types.AUTH_SIGN_OUT,
    });
  };
}

export function resetPassword(credentials) {
  return async (dispatch) => {
    dispatch({ type: types.AUTH_RESET_PASSWORD_REQUEST });

    return authResetPassword(credentials)
      .then((response) => {
        dispatch({
          type: types.AUTH_RESET_PASSWORD_SUCCESS,
          email: response.email,
        });
      })
      .catch((error) => {
        console.error("reset", error);
        dispatch({ type: types.AUTH_RESET_PASSWORD_FAILURE });
        throw error;
      });
  };
}

export function resetPasswordConfirm(credentials) {
  return async (dispatch) => {
    dispatch({ type: types.AUTH_CHANGE_PASSWORD_REQUEST });
    try {
      const u = await Auth.forgotPasswordSubmit(credentials.username, credentials.code, credentials.password);
      dispatch({
        type: types.AUTH_RESET_PASSWORD_SUCCESS,
      })
    } catch (error) {
      dispatch({
        type: types.AUTH_RESET_PASSWORD_FAILURE,
      });
      throw error;
    }
  }
}

export function changePassword(credentials) {
  return async (dispatch) => {
    dispatch({ type: types.AUTH_CHANGE_PASSWORD_REQUEST });
    try {
      const user = await Auth.currentAuthenticatedUser();
      const u = await Auth.changePassword(user, credentials.current_password, credentials.new_password);
      dispatch({
        type: types.AUTH_CHANGE_PASSWORD_SUCCESS,
      })
    } catch (error) {
      dispatch({
        type: types.AUTH_RESET_PASSWORD_FAILURE,
      });
      throw error;
    }
  }
}

export function setSignUpUser(credentials) {
  return async (dispatch) => {
    dispatch({
      type: types.AUTH_SIGN_UP_SUCCESS,
      user: {
        username: credentials.username
      },
      userConfirmed: false,
      userSub: null
    })
  };
}

export async function resendConfirmCode(credentials) {
  return new Promise(async r => {
    try {
      await Auth.resendSignUp(credentials.username);
      r(true);
    } catch (error) {
      console.log("resendConfirmCodeError", error);
      r(false);
    }
  })
}

export function setAccessToken(credentials) {
  return async (dispatch) => {
    dispatch({
      type: types.AUTH_NEW_ACCESS_TOKEN,
      access_token: credentials.access_token
    })
  };
}

export function checkAccessToken(user) {
  return async (dispatch) => {
    const token = user.id.signInUserSession.accessToken.jwtToken;
    const refreshToken = user.id.signInUserSession.refreshToken.token;
    const clientId = user.id.pool.clientId;
    const jwt = jwt_decode(token);
    let fark = jwt.exp * 1000 - Date.now();
    fark = fark / 60 / 60 / 1000;
    console.log(Math.floor(fark) + " Saat Kaldı");
    if (jwt.exp * 1000 < Date.now()) {
      console.log("Refresh Token");
      axios.post("https://pensoruser.auth.us-east-1.amazoncognito.com/oauth2/token", querystring.stringify({
        client_id: clientId,
        grant_type: "refresh_token",
        refresh_token: refreshToken
      })).then(async res => {
        /*await dispatch(
          setAccessToken({ access_token: res.data.access_token })
        );*/
        if (res.status == 200) {
          dispatch({
            type: types.AUTH_NEW_ACCESS_TOKEN,
            access_token: res.data.access_token
          })
        } else {
          dispatch({
            type: types.AUTH_SIGN_OUT,
          });
        }
      }).catch(async error => {
        dispatch({
          type: types.AUTH_SIGN_OUT,
        });
        console.log(error);
      })
    }
  };
}

export function apiUserInfo(user) {
  return async (dispatch) => {
    axios.get("https://z1m2rxjeg1.execute-api.us-east-1.amazonaws.com/Prod/" + user.username).then(async res => {
      if (res.status == 200) {
        console.log("apiUserInfo", res.data)
        dispatch({
          type: types.AUTH_API_USER_INFO,
          userInfo: res.data
        })
      }
    }).catch(async error => {
      /*dispatch({
        type: types.AUTH_SIGN_OUT,
      });*/
      console.log(error);
    })
  };
}