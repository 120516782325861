/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:8092c77a-e6b4-41c7-a2e1-2fa81a4e84ea",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_hevCJidnC",
    "aws_user_pools_web_client_id": "65145f5uicgupo2agggqrvvlas",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "pensoruser-20221029130546-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "http://pensoruser-20221029130546-hostingbucket-dev.s3-website-us-east-1.amazonaws.com",
    "aws_user_files_s3_bucket": "pensoruser918798a566014239aa5c30ca53e289aa213619-dev",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
