import mock from "../utils/mock";
import firebase from "firebase/app";
import "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAdeaiaB_DGDk-Rx-bX7Kp_mqupkHYTFWk",
  authDomain: "pensor-dash.firebaseapp.com",
  projectId: "pensor-dash",
  storageBucket: "pensor-dash.appspot.com",
  messagingSenderId: "383070049066",
  appId: "1:383070049066:web:642d328be56966f818c453",
  measurementId: "G-7JEY1SJ39S"
};

firebase.initializeApp(firebaseConfig);

const userData = {
  id: "12345",
  email: "birand@pensor.tech",
  name: "Birand Önen",
};

function firebaseAuth(email, password) {
  const user = firebase.auth().signInWithEmailAndPassword(email, password)
                              .then((user) => { return(user.user.uid) })
                              .catch((error) => { return error });
  return user;
}
mock.onPost("/api/auth/sign-in").reply((config) => {
  const { email, password } = JSON.parse(config.data);

  try {
    firebase.auth().signInWithEmailAndPassword(email,password)
  } catch(e) {
    console.log(e);
  }
  if (email === "birand@pensor.tech" && password === "unsafepassword") {
    return [200, userData];
  }
  
  return [401, { message: "Please check your email and password" }];
});

mock.onPost("/api/auth/sign-up").reply(() => {
  return [200, userData];
});

mock.onPost("/api/auth/reset-password").reply(() => {
  return [200, userData];
});
