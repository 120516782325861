import axios from "axios";
import { Auth } from 'aws-amplify';

export function signIn(credentials) {
  return new Promise((resolve, reject) => {
    try {
      const user = Auth.signIn(credentials.email, credentials.password)
      //const user = {"email":"birand@pensor.tech","password":"brnd_1202."}
      resolve(user);
    } catch (error) {
      console.log(error);
    }
  });
}

export function signUp(credentials) {
  return new Promise(async (resolve, reject) => {
    try {
      const { user } = await Auth.signUp({
        username: credentials.email,
        password: credentials.password,
      });
      console.log(user);
      resolve(user);
    } catch (error) {
      reject(error);
    }
  });
}

export function resetPassword(credentials) {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await Auth.forgotPassword(credentials.email);
      resolve(true);
    } catch (error) {
      reject(error);
    }
    /*axios
      .post("/api/auth/reset-password", credentials)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });*/
  });
}

/*
export function changePassword(credentials) {
  return new Promise((resolve, reject) => {
    Auth.currentAuthenticatedUser()
      .then(user => {
        Auth.changePassword(user, credentials.current_password, credentials.new_password);
      })
      .then(data => resolve(data))
      .catch(err => reject(err));
  })
}
*/
export function changePassword(credentials) {
  Auth.currentAuthenticatedUser()
    .then(user => {
      return Auth.changePassword(user, credentials.current_password, credentials.new_password);
    })
    .then(data => console.log(data))
    .catch((error) => console.log(error));
}