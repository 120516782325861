import * as types from "../../constants";

const initial = {
    projects: null,
}
export default function reducer(state = initial, actions) {
    switch (actions.type) {
        case types.DATA_STATE_PROJECTS:
            return {
                ...state,
                projects: actions.projects
            };
        default:
            return state;
    }
}
