import { combineReducers } from "redux";

import themeReducer from "./themeReducer";
import authReducer from "./authReducer";
import deviceReducer from "./deviceReducer";
import scriptReducer from "./scriptReducer";
import dataReducer from "./dataReducer";

export const rootReducer = combineReducers({
  themeReducer,
  authReducer,
  deviceReducer,
  scriptReducer,
  dataReducer
});
