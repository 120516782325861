import * as types from "../../constants";

export default function reducer(state = {}, actions) {
    switch (actions.type) {
        case types.SCRIPT_STATE_SUCCESS:
            return {
                ...state,
                scripts: actions.scripts,
            };
        case types.SCRIPT_STATE_FAILURE:
            return {
                ...state,
                scripts: undefined,
            };
        case types.SCRIPT_DETAILS_SUCCESS:
            return {
                ...state,
                details: actions.details,
            };
        case types.SCRIPT_DETAILS_FAILURE:
            return {
                ...state,
                details: undefined,
            }
        default:
            return state;
    }
}